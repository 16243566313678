/* Import the Lora font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');

body {
  font-family: 'Lora', serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.App {
  text-align: center;
  width: 100%;
}

.App-header {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 8px;
  width: 100%;
}

h1 {
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

label {
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
}

input, select {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.intl-tel-input {
  margin-bottom: 15px;
}

button {
  background-color: #8b0000;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
}

button:hover {
  background-color: #a52a2a;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.postcode-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.postcode-row input {
  flex: 1;
}

.postcode-row .loading {
  font-size: 14px;
  color: #888;
}

.error-message {
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
}

.privacy-policy {
  margin-bottom: 20px;
}

.privacy-policy input {
  margin-right: 10px;
}

.privacy-policy a {
  color: #8b0000;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}
